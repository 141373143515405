import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Hero } from 'src/components/organisms/Hero';
import { Button } from 'src/components/atoms/Button';
import { Link } from 'src/containers/Link';
import { Image } from 'src/containers/Image2';
import { HubspotForm } from 'src/components/atoms/HubspotForm';
import { Card } from 'src/components/atoms/Card';

/*
  image
  ctas
  customData
    flags
      alternative: boolean
      primary?: boolean;
      wavey?: boolean; (exclusive)
      sloped?: boolean; (exclusive)
      width?: 'large' | 'xlarge';
      subtitle?: ReactNode;
      subtitleWidth?: 'small' | 'medium' | 'large' | 'xlarge' | 'full';
 */
export default function LegacyHeader({ heading, contentNode, image, ctas, blocks, customData }) {
  customData = JSON.parse(customData || {});

  const hubspotForm = blocks.find((block) => block.__typename == 'DatoCmsHubspotForm');

  return (
    <Hero
      title={heading}
      subtitle={<MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
      category={null}
      actions={
        ctas.length ? (
          <>
            <Button to={ctas[0].url} aria-label={ctas[0].a11yText || ctas[0].text}>
              {ctas[0].text}
            </Button>
            {ctas.length > 1 && (
              <>
                <br />
                <Link
                  to={ctas[1].url}
                  style={{
                    display: 'inline-block',
                    color: 'inherit',
                    fontSize: '15px',
                    textDecoration: 'underline',
                  }}
                >
                  {ctas[1].text}
                </Link>
              </>
            )}
          </>
        ) : null
      }
      image={
        hubspotForm ? (
          <Card style={{ maxWidth: '470px', minHeight: '788px', margin: '0 auto' }}>
            {hubspotForm.heading && <h3>{hubspotForm.heading}</h3>}
            <HubspotForm stacked formId={hubspotForm.formId} />
          </Card>
        ) : (
          image && <Image data={image} width={image.width / 2} centered alt={image.alt} noMargin />
        )
      }
      centered
      {...(customData.flags || {})}
    />
  );
}
